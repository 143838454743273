import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=3acfa164&scoped=true"
import script from "./Modal.vue?vue&type=script&setup=true&lang=js"
export * from "./Modal.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Modal.vue?vue&type=style&index=0&id=3acfa164&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3acfa164",
  null
  
)

export default component.exports